<template>
  <el-breadcrumb separator=">">
      <el-breadcrumb-item >主页</el-breadcrumb-item>
      <el-breadcrumb-item>{{breadCrumb}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
   name: 'BreadCrumb',
   data(){
       return{

       }
   },
   props:{
       breadCrumb:{
           type: String,
       }
   },
    created(){
      
    }
}
</script>
<style lang="scss" scoped>
.layout{
    .el-breadcrumb{
        padding: 15px 15px 0 15px;
    }
}
</style>
