import request from '@/utils/request'

const apiPrefix = `${process.env.VUE_APP_BASE_API}`
// 获取阀门型号下拉框
const getTextValve = (params, config = {}) => {
    return request.get(apiPrefix + '/ValveType/textValue', { params: params, ...config })
}
// 获取制造单位下拉框
const getFactoryTextValve = (params, config = {}) => {
    return request.get(apiPrefix + '/Factory/textValueList', { params: params, ...config })
}
// 获取总公司下拉框
const getEnterpriseTextValve = (params, config = {}) => {
    return request.get(apiPrefix + '/Enterprise/textValueList', { params: params, ...config })
}
// 获取角色下拉框
const getRoleTextValve = (params, config = {}) => {
    return request.get(apiPrefix + '/Role/textValueList', { params: params, ...config })
}
// 用户账号状态
const getUserState = () => {
    return {
        status:200,
        data:[
            {
                value:'false',
                text:"启用"
            },
            {
                value:'true',
                text:"禁用"
            },
        ]
    }
}
export default {
    getTextValve,
    getFactoryTextValve,
    getEnterpriseTextValve,
    getRoleTextValve,
    getUserState
}


