import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.headers['Pragma'] = 'no-cache'

const service = axios.create({
  baseURL: '/', // url = base url + request url
  // baseURL: process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_BASE_API, // url = base url + request url
})

// 拦截请求
service.interceptors.request.use(
  config => {
    const token = store.getters.token
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// 拦截响应
service.interceptors.response.use(
  response => {
    //console.log(response)
    const { data } = response
    if (!data.success && data.msg) {
      Vue.prototype.$message.error({
        message: data.msg,
      })
    }
    return response
  },
  async error => {
    const res = { success: false, code: 0, msg: '' }
    if (error?.response) {
      if (error.config._request) {
        return res
      }
      const { config, data, status } = error.response
      if (_.isNumber(status)) {
        res.code = status
      }
      if (_.isPlainObject(data)) {
        _.merge(res, data)
      } else if (_.isString(data)) {
        res.msg = data || error.response.statusText
      }
      // 错误消息
      if (res.msg) {
        Vue.prototype.$message.error({
          message: res.msg,
        })
      }
    }
    return res
  }
)
export default service
