
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getLogin } from '@/api/login'
import { getleftMenu } from '@/api/leftMenu'
import { addRoutes } from '@/router'
import { setRedirect } from '@/utils';
const state = {
  token: getToken('token'),
  menus: [],
  hostConfig: getToken('hostConfig')
}

const mutations = {
  setToken: (state, token) => {
    state.token = token;
    setToken('token',token)
  },
  setUser: (state, hostConfig) => {
    state.hostConfig = hostConfig;
    setToken('hostConfig',hostConfig)
  },
  setMenus: (state, menus) => {
    state.menus = menus
  },
}

const actions = {
  async login({ commit }, paras) {
    const res = await getLogin(paras);
    //console.log(res)
    if (res.status == 200) {
      const token = res.data.accessToken;
      const hostConfig = JSON.stringify(res.data);
      commit('setToken', token);
      commit('setUser', hostConfig);
    }
    return res
  },

async getLoginInfo({ commit }) {
  //路由菜单数据
  const res = await getleftMenu();
  //console.log(res)
  if(res.status == 200){
    let rootMenu = res.data;
    rootMenu.forEach(item=> {
      if(!item.viewPath){
        item.redirect = setRedirect(item);
      }else{
        item.redirect = item.viewPath;
      }
    })
    commit('setMenus', rootMenu)
    addRoutes(rootMenu)
    return rootMenu
  }
    
  },

  logout({ commit }) {
    commit('setToken', '');
    commit('setMenus', '');
    removeToken('token');
    removeToken('hostConfig')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
