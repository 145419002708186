<!--
 * 容器组件
 * 使用说明
 -->
<template>
    <div class="flex-col">
        <div class="flex-content">
          <div class="add"  v-if="$slots.add">
              <slot name="add" />
          </div>        
          <div class="flex-wrapper">
              <slot />
          </div>
          <div class="pager-container" v-if="$slots.pager">
            <slot name="pager" />
          </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'XkContainer', 
}
</script>
<style lang="scss" scoped>
$pad-20:20px 20px 0 20px;
$box-shadow-col: 0 1px 5px rgba(0,0,0,0.1);


.flex-col{
  display: flex;
  flex-direction: column;
  .add{
    @include background-white;
    padding: $pad-20;
  }
  .flex-content{
    display: flex; 
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    box-shadow: $box-shadow-col
  }
  .flex-wrapper{
    flex:1;
    overflow: hidden;
    @include background-white; 
    padding: 0px 20px 20px 20px;
  }
  .pager-container{
    text-align: center;
    @include background-white;
    padding-bottom: 20px;
    border-radius: 0 0 5px 5px;
  }
}

</style>
