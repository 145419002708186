import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Menu/`

// 获取左侧菜单
export const getleftMenu = (params, config = {}) => {
    return request.get(apiPrefix + 'leftMenu', { params: params, ...config })
}
// 获取菜单列表
export const getMenuList = (params, config = {}) => {
    return request.get(apiPrefix + 'list', { params: params, ...config })
}
// 获取菜单数结构
export const getMenuTree = (params, config = {}) => {
    return request.get(apiPrefix + 'menuTree', { params: params, ...config })
}
// 新增菜单
export const addMenu= (params, config = {}) => {
    return request.post(apiPrefix + 'add', params, config)
}
// 菜单修改
export const editMenu = (params, config = {}) => {
    return request.put(apiPrefix + 'edit', params, config)
}
// 菜单详情
export const detailMenu = (params, config = {}) => {
    return request.get(apiPrefix + 'detail', { params: params, ...config })
}
// 菜单删除
export const deleteMenu = (params, config = {}) => {
    return request.delete(apiPrefix + 'delete', { params: params, ...config })
}
// 获取菜单功能权限
export const getButtonsForMenu = (params, config = {}) => {
    return request.get(apiPrefix + 'getButtonsForMenu', { params: params, ...config })
}
// 获取按钮列表
export const getbuttonList = (params, config = {}) => {
    return request.get(apiPrefix + 'buttonList', { params: params, ...config })
}
// 新增按钮
export const addButton= (params, config = {}) => {
    return request.post(apiPrefix + 'addButton', params, config)
}
// 按钮修改
export const editButton = (params, config = {}) => {
    return request.put(apiPrefix + 'editButton', params, config)
}
// 按钮详情
export const detailButton = (params, config = {}) => {
    return request.get(apiPrefix + 'buttonDetail', { params: params, ...config })
}
// 按钮删除
export const deleteButton = (params, config = {}) => {
    return request.delete(apiPrefix + 'deleteButton', { params: params, ...config })
}
