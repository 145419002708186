<!--左侧菜单-->
<template>
  <div class="layout-menu">
    <el-submenu v-if="item.children && item.children.length > 0" :index="item.id + ''" >
      <template #title>
        <XkSvgIcon :iconClass="item.icon"/>
        <span>{{ item.label }}</span>
      </template>
      <el-menu-item-group>
      <Menu v-for="child in item.children" :key="child.id" :item="child" />
      </el-menu-item-group>
    </el-submenu>
    <template v-else>
        <el-menu-item :index="item.viewPath">
          <XkSvgIcon :iconClass="item.icon"/>
          <span>{{ item.label }}</span>
        </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
   name:'Menu',
   props:{
       item:{
           type: Object,
       }
    },
   data(){
       return{

       }
   },
   created(){

   }
}
</script>

<style lang="scss" scoped>
.layout{
  .layout-menu{
    ::v-deep .el-submenu__title{
      height: 46px;
      line-height: 46px;
      font-weight: bold;
          &.is-active,&:hover {
              background-color: rgba(60,141,188,0.1);
          }
          .svg-icon{
              color: #303133;
              font-size: 16px;
              margin-right: 6px;
          }
      }
    ::v-deep .el-menu-item{
          margin: 0 auto;
          height: 40px;
          line-height: 40px;
          color: #999999;
          margin-bottom: 10px;
            &.is-active,&:hover {
              color: #02A4E5;
              font-weight: bold;
              background-color:rgba(0,112,192,0.1);
            }
            .svg-icon{
              font-size: 16px;
              margin-right: 6px;
          }
    }
  }
}
</style>