import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/user/`

// 用户列表
export const getUserList = (params, config = {}) => {
    return request.get(apiPrefix + 'list', { params: params, ...config })
}
// 新增用户
export const addUser = (params, config = {}) => {
    return request.post(apiPrefix + 'add', params, config)
}
// 用户修改
export const editUser = (params, config = {}) => {
    return request.put(apiPrefix + 'edit', params, config)
}
// 用户详情
export const detailUser = (params, config = {}) => {
    return request.get(apiPrefix + 'detail', { params: params, ...config })
}
// 用户删除
export const deleteUser = (params, config = {}) => {
    return request.delete(apiPrefix + 'delete', { params: params, ...config })
}
// 重置密码
export const resetPassword = (params, config = {}) => {
    return request.put(apiPrefix + 'resetPassword', params, config)
}
// 修改用户密码
export const changePassword = (params, config = {}) => {
    return request.put(apiPrefix + 'changePassword', params, config)
}