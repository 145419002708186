<!--顶部导航-->
<template>
  <div class="nav-container">
    <!-- <div class="nav-title"><img src="~@/assets/images/logo.png" width="200"/><span></span>罗达莱克斯阀门档案管理系统</span></div> -->
    <div class="nav-title"><img src="~@/assets/images/home.png" width="35"/><span>阀门信息化追溯系统</span></div>
    <div class="nav-right-menu">
      <el-dropdown>
        <span class="nav-el-dropdown">
          <span>{{ hostConfig.loginName }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="respassword()"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item @click.native="logout()">账户退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹窗 -->
    <el-dialog
      class="dialogbox"
      title="密码修改"
      v-if="passWordDialog"
      @close="closeMaxDialog"
      :visible.sync="passWordDialog"
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form
        class="user-account-key"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="原密码" prop="password">
          <el-input
            type="password"
            placeholder="请输入原密码"
            v-model="form.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            placeholder="请设置新密码"
            v-model="form.newPassword"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPassword2" show-password>
          <el-input
            type="password"
            placeholder="请确认新密码"
            v-model="form.newPassword2"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-close"
            @click="
              passWordDialog = false;
              form = {};
            "
            >取消</el-button
          >
          <el-button icon="el-icon-check" @click="onSubmit('form')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { changePassword } from "@/api/user";
import { toLogout } from "@/router"; //登出
import { mapGetters } from "vuex";
export default {
  name: "Nav",
  computed: {
    ...mapGetters(["hostConfig"]),
  },
  data() {
    //此处即表单发送之前验证
    let validateNew = (rule, value, callback) => {
      let Reg = /^[a-zA-Z0-9]{6,20}$/;
      if (!Reg.test(value)) {
        callback(new Error("大小写字母、数字、大小写字母数字组合、长度6-20位"));
      } else {
        callback();
      }
    };
    let validateNewPassword = (rule, value, callback) => {
      let Reg = /^[a-zA-Z0-9]{6,20}$/;
      if (!Reg.test(value)) {
        callback(new Error("大小写字母、数字、大小写字母数字组合、长度6-20位"));
      } else {
        if (value === this.form.password) {
          callback(new Error("新密码不能与原密码相同!"));
        } else {
          callback();
        }
      }
    };
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error("与新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      isCollapse: false,
      passWordDialog: false,
      form: {
        password: "",
        newPassword: "",
        newPassword2: "",
      },
      rules: {
        password: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { validator: validateNew, trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请设置新密码", trigger: "blur" },
          { validator: validateNewPassword, trigger: "blur" },
        ],
        newPassword2: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          { validator: validateNewPassword2, trigger: "blur" },
        ],
      },
    };
  },
  props: {},
  watch: {},
  methods: {
    // 关闭
    closeMaxDialog() {
      this.form = {};
      this.passWordDialog = false;
    },
    //登出
    logout() {
      this.$confirm("确认退出吗?", "提示")
        .then(() => {
          toLogout();
        })
        .catch(() => {});
    },
    // 修改密码弹窗
    respassword() {
      this.passWordDialog = true;
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
         
        if (valid) {
          let res;
          let params = {
            guid: this.hostConfig.guid,
            oldPassword: this.form.password,
            newPassword: this.form.newPassword2,
          };
          try {
            res = await changePassword(params);
          } catch (error) {
            return;
          }
          if (res?.status == 200) {
            this.$message({
              message: "恭喜你，密码修改成功",
              type: "success",
            });
            this.passWordDialog = false;
          }
        } else {
          //this.$message.error('请正确填写表单')
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  .nav-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .nav-title {
      height: 48px;
      flex: 1;
      font-weight: 700;
      font-style: normal;
      font-size: 22px;
      display: flex;
      align-items: center;
      letter-spacing:1px;
      img{
        margin-right: 10px;
      }
    }
    .nav-right-menu {
      .nav-el-dropdown {
        cursor: pointer;
        @include nav;
        .el-icon-arrow-down {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
