<!--
@description: 通用下拉框组件
@prop  {url<String>}       接口URL
@prop  {urlParams<Object>} 接口URL的参数
@prop  {placeholder<string>}  placeholder
@prop  {label<string>}    label字段
@prop  {value<string>}    value字段
-->
<template>
  <el-select
    v-model="values"
    :placeholder="placeholder"
    @change="handleChange"
    filterable
    clearable
    :disabled="disabled"
    style="width:100%"
  >
    <el-option
      v-for="item in options"
      :key="item[value]"
      :label="item[lable]"
      :value="item[value]"
    >
    </el-option>
  </el-select>
</template>

<script>
import common from '@/api/common';
export default {
  data() {
    return {
      values: "",
      options: [],
      disabled:false
    };
  },
  props: {
    placeholder: {
      type: String,
    },
    url: {
      type:String,
    },
    urlParams: {
      type: Object,
      default() {
        return {};
      },
    },
    lable: {
      type: String,
      default: "name",
    },
    value: {
      type: String,
      default: "id",
    },
    val:{
      type: String,
    }
  },
  watch:{
    val:{
      handler(nVal){
        this.values = nVal;       
      },
      immediate:true,
    },
    urlParams:{
      handler(val){
        //console.log(val);
        this.getOptions();
      },
      deep:true
    }
  },
  created() {
      this.getOptions();
  },
  methods: {
    async getOptions() {
       try {
        let res = await common[this.url](this.urlParams);
        if (res?.status == 200) {
          this.options = res.data;
          if(this.hostConfig.type == 3&&this.url == "getFactoryTextValve"){
            this.disabled = true;        
            this.values = res.data[0].value;
            this.$emit("update:val", this.values);
          }       
        }
      } catch (error) {}
    },
    handleChange() {
      this.$emit("update:val", this.values);
      this.$emit("handleChange", this.values);
    },
  },
};
</script>

<style>
</style>