/* 导出excel数据时，数据处理
 * @param {*} filterVal
 * @param {*} jsonData
 */
export function formatJson(filterVal, jsonData) {
  return jsonData.map((v) =>
    filterVal.map((j) => {
      return v[j];
    })
  );
}

/**
 * 时间格式化  formatDate(date,'yyyy-MM-dd')
 */
export function formatDate(time, fmt) {
  let date = new Date(time)
  let o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "H+": date.getHours(), // 小时
    "h+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}
/**
 * 日期控件默认当前月
 */
export function getmonthValue(){
  let date = new Date()
  let y = date.getFullYear()
  let m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  let d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  return y + '-' + m + '-' + d
}
/**
 * 日期控件默认上个月
 */
 export function getlastMonthValue(){
  let date = new Date()
  let y = date.getFullYear()
  let m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth()) : date.getMonth())
  let d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  return y + '-' + m + '-' + d
}
/**
 * 获取今天之前N天
 * 比如getLastDay(7) 获取7天前日期
 */
 export function getLastDay(day){
  var date = new Date();
  date.setDate(date.getDate() - day);
  let y = date.getFullYear();
  let m = ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1));
  let d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  return y + '-' + m + '-' + d
}
/**
 * 去中间空格
 */
 export function trimName(val, value) {
    this[val][value] = this[val][value].replace(/\s+/g, "");
}
// 树形数据转换工具
/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @param pKey
 * @returns {*}
 */
 export function translateDataToTree(list, pKey = 'parent_id') {
  let data = JSON.parse(JSON.stringify(list))
  const parent = data.filter(
    (value) =>
      value[pKey] === 'undefined' || value[pKey] == null || value[pKey] == 0
  )
  const children = data.filter(
    (value) =>
      value[pKey] !== 'undefined' && value[pKey] != null && value[pKey] > 0
  )
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current[pKey] === parent.pk_id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          translator([current], temp)
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  translator(parent, children)
  return parent
}

/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(list) {
  let data = JSON.parse(JSON.stringify(list))
  
  const result = []
  data.forEach((item) => {
    const loop = (data) => {
      const child = data.children
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i])
        }
        delete data.children
      }
      result.push(data)
    }
    loop(item)
  })
 
  return result
}
export function listToTree(list = [], idField = 'menuGuid', parentIdField = 'parentGuid') {
  let data = list
  const tree = []
  const hash = {}
  const childrenField = 'children'
  for (let i = 0, l = data.length; i < l; i++) {
    const d = data[i]
    
    hash[d[idField]] = d
  }

  for (let i = 0, l = data.length; i < l; i++) {

    const d = data[i]

    const parentID = d[parentIdField]
    if (parentID === null || parentID === '' || parentID === 0) {
      tree.push(d)
      continue
    }

    const parent = hash[parentID]
    if (!parent) {
      continue
    }

    let children = parent[childrenField]
    if (!children) {
      children = []
      parent[childrenField] = children
    }
    children.push(d)
  }
  return tree
}