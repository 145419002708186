import Vue from "vue";
import XkContainer from "@/components/XkContainer";
import XkPagination from "@/components/XkPagination";
import XkMaxDialog from "@/components/XkMaxDialog";
import XkDropDown from "@/components/drop-down/XkDropDown";
import XkConfirmButton from "@/components/XkConfirmButton";
import * as globalMethods from "@/utils/global"
import { mapGetters } from 'vuex';
const myMixin = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          // 一些通用表格宽度设置，如不用请在table单独设置 （wangjieqiong）
          tableIndexWidth: 55, //表格序号列的宽度
          //comPageSize:Number(localStorage.getItem('pageSize'))||20,//每页显示大小
          comPageSize:10,//每页显示大小
          buttons:[],//按钮功能 
        };
      },
      computed:{
        ...mapGetters(['hostConfig']),
      },
      //监听操作按钮
      watch: {
        buttons(val) {
            this.doLayout();
        }
      },  
      components: {
        XkContainer,
        XkPagination,
        XkDropDown,
        XkConfirmButton,
        XkMaxDialog
      },
      methods: {
        ...globalMethods,
        //表格变化执行
        doLayout() {
          this.$nextTick(() => {
              this.$refs.mainTable.doLayout()
          })
        },
      },
      created(){

      }
    });
  },
};

Vue.use(myMixin);

export default myMixin;
