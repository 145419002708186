<!--
* 带全屏的dialog
@author: wangjieqiong 
@props: {Array} title  (dialog的标题)
@closeFun:  (传入的关闭event)
@maxFun:    (传入的最大event)
-->
<template>
  <el-dialog
    v-dialogDrag
    ref="maxDialog"
    custom-class="dialogHeightFull maxDialog"
    :title="title"
    :fullscreen="fullFlag"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    :width="width"
    :top="top"
    v-if="dialogVisible"
    @closed="closeMaxDialog"
  >
    <div slot="title" class="maxDialog-header">
      <div class="maxDialog-title"><span>{{title}}</span></div>
      <div class="maxDialog-icon">
        <i :class="fullFlag? 'el-icon-copy-document' : 'el-icon-full-screen' " @click="IsFullscreen"></i>
        <i class="el-icon-close" @click="closeMaxDialog"></i>
      </div>
    </div>
    <slot name="content" :style="{height:height}"></slot>
 </el-dialog>
</template>

<script>
export default {
  name:'maxDialog',
  props: {
    dialogVisible:{
      type: Boolean,
      default: false
    },
    titleVisible:{
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '950px'
    },
    height: {
      type: String,
      default: '100%'
    },
    top:{
      type: String,
      default: '30px'
    },
    title: {
      type: String,
      default: ''
    },
    isfullscreen: {
      type: Boolean,
      default: true // 默认全屏
    }
  },
  data() {
    return {
      full: true, // 全屏
    }
  },
  computed:{
    fullFlag: {
      get: function() {
        return this.full ?? this.isfullscreen;
      },
      set: function(n) {
        return this.full
      }
    }
  },
  methods: {
    // 全屏 切换
    IsFullscreen() {
      if(this.isfullscreen == true){
        this.full = this.full===null ? false : !this.full
      }else{
        this.full = this.full===null ? true : !this.full
      }
      // 传过来的全屏钩子函数
      this.$emit('maxFun',this.full)
    },
    // 关闭
    closeMaxDialog(){
      this.full = null
      this.$emit('update:dialogVisible',false)
      // 传过来的关闭钩子函数
      this.$emit('closeFun')
    }
  },
  created(){
    this.full = this.isfullscreen
  },
}
</script>

<style scoped>
>>>.el-dialog__header{
    background-color: #f2f2f2;
    /* height: 48px; */
    padding: 10px 20px 10px;
  }

.maxDialog-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.maxDialog .maxDialog-icon i{
  display: inline-block;
  height: 28px;
  width: 28px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
 }
.maxDialog .maxDialog-icon i:hover{
   background-color: #ddd;
 }
.maxDialog .maxDialog-icon i::before{
   font-size: 80%;
 }
/deep/ .maxDialog.dialogHeightFull.el-dialog{
  margin-bottom: 0;
  overflow-y: hidden;
  /* max-height: calc(100% - 30px - 30px); */
}
::v-deep .el-dialog__body{
  padding: 0;
}
.el-dialog__wrapper{
  overflow: hidden!important;
}

</style>
