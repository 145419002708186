import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import defaultSettings from '@/settings'
import Layout from '@/layout'
import { _import } from '@/utils/lazyLoading' //获取组件的方法

/**
 * 重写路由的push方法
 */
 const routerPush = Router.prototype.push
 Router.prototype.push = function push(location) {
   return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)
const staticRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: _import('/login'),
        hidden: true,
        meta: {
          title: '登录'
        }
    },
]

const router = new VueRouter({
    routes: staticRoutes,
    base: process.env.BASE_URL,
})

//动态路由组件导入
function dynamicRoutes(menus = []){
    //console.log(menus)
    const routes = {
        path: '/',
        component: Layout,
        children: [],
        // name: 'Layout',
        meta: {},
        
    }
    menus.forEach(n => {
        if(n.children.length > 0){
            n.children.forEach(m => {
                try {
                    const route = {
                    name: m.name,
                    path: m.viewPath,
                    component: _import(m.viewPath),
                    meta: {
                        title: m.label,
                        menuId: m.id
                    }
                    }  
                    routes.children.push(route)
                } catch (error) {
                    Message.error(`导入组件${m.viewPath}.vue失败`)
                }
            })
            
        }       
      })
    // 修复无首页时不显示404
    routes.children.push({
        path: '',
        redirect:''
    })

    routes.children.push({
        path: '*',
        component: _import('/error/404'),
    })
      return [routes] 
}
const createRouter = () => new Router({
    routes: staticRoutes
})
//写一个重置路由的方法，切换用户后，或者退出时清除动态加载的路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}
// 添加动态路由
export function addRoutes(menus = []) {
    if (menus && menus.length > 0) {
      const dynamicRoutesList = dynamicRoutes(menus)
      router.options.routes = staticRoutes.concat([...dynamicRoutesList])//显示静态和动态路由
      resetRouter()
      router.addRoutes(dynamicRoutesList)
    }
}

// 获取页面标题
function getPageTitle(pageTitle) {
    return `${defaultSettings.title} - ${pageTitle}`
}

// 登录
function toLogin(to, next) {
    if (to.path === '/login') { 
        next()
      } else {
        next(`/login?redirect=${to.path}`)
    }
}

// 登出
export function toLogout() {
    store.dispatch('user/logout')
    router.push('/login')
}

// 路由全局前置守卫
router.beforeEach(async (to, from, next) => {
    document.title = getPageTitle(to.meta.title)
    const token = getToken('token')
    if(token){
        if (to.path === '/login') {
            toLogin(to, next)
          } else {
            if (store.getters.menus?.length > 0) {
                next()
            } else {
                let res = await store.dispatch('user/getLoginInfo')
                    if (res) {
                        next({ ...to, replace: true })
                    } else {
                        toLogin(to, next)
                    }        
            }
        }
    }else{
        toLogin(to, next)
    }
})

export default router
