import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.scss'
import lodash from 'lodash'
import '@/icons'
import '@/mixin'
import * as filters from './filters/index'
Vue.prototype.$_ = lodash
Vue.use(ElementUI);
Vue.config.productionTip = false
import '@/directive' //全局指令注册
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
