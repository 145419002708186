<template>
  <el-container class="layout">
        <el-header>
            <Nav></Nav>
        </el-header>
    <el-container class="layout-tabs">      
        <el-aside>
            <el-scrollbar class="page-component__scroll">      
                <el-menu :default-active="$route.path" :default-openeds="openeds" :unique-opened="false" router class="sidebar">
                    <Menu v-for="menu in sideMenuTree" :key="menu.id" :item="menu"></Menu>
                </el-menu>
            </el-scrollbar>
        </el-aside>
        <el-main>
            <BreadCrumb :breadCrumb="breadCrumb"></BreadCrumb>
            <router-view class="layout-content"></router-view>
        </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Nav from "./components/Nav";
import Menu from "./components/Menu";
import BreadCrumb from "./components/BreadCrumb";
import { listToTree } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: "Layout",
  data() {
    return {
        openeds: [],
        sideMenuTree:[],
    };
  },
  components: {
    Nav, //顶部导航
    Menu,//左侧菜单
    BreadCrumb,//面包屑
  },
  computed:{
    ...mapGetters(['menus']),
    breadCrumb() {
      return this.$route.meta.title
    },
  },
  watch: {
    $route: {
          handler(to,from) {
               //console.log('layout',this.$route.path)
          },
          deep:true,
          immediate: true
      }
  },
  created(){
      this.getMenus()
  },
  methods:{
      //左侧菜单显示
      isCollapseOn(parms){
        this.isCollapse = parms
      },
      //获取菜单
      getMenus(){      
         if (this.menus?.length > 0) {
              const cloneMenus = _.cloneDeep(this.menus);                 
              this.sideMenuTree = cloneMenus;        
              this.openeds = this.menus.map(l => l.id + '')  
         }
      },
  }
};
</script>
<style lang="scss" scoped>

.layout{
    height: 100%;
   ::v-deep .el-header {
      padding: 0 20px;
      @include nav;
    } 
   ::v-deep .el-aside{
      width: 230px !important;
      height: 100%;
      background-color: #fff;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
   ::v-deep .el-menu{
      border-right: 0;
      background-color: #fff;
    }
   ::v-deep .el-main{
      padding: 0px;
      display: flex;
      flex-direction: column;
    }
    ::v-deep .el-scrollbar__wrap{
        overflow-x:hidden;
    }
    .layout-tabs{
      height: 100%;
      overflow: hidden;
      .page-component__scroll{
        flex: 1;
        overflow-y: auto;
      }        
    }
    .layout-content{
      display: flex;
      flex: 1;
      height: 0;
      margin: 15px 10px;
      border-radius: 5px;
    }
}

</style>
