<!--
一般提交
<my-confirm-button @click="syncApi" :icon="'el-icon-refresh'" style="margin:0px;">
    <template #content>
      <p>确定要同步Api吗？</p>
    </template>
    同步Api
</my-confirm-button>

行删除
<my-confirm-button type="delete" @click="handleDel($index, row)" :loading="row._loading"/>

表单验证
<my-confirm-button type="submit" @click="editSubmit" :validate="editFormvalidate" :loading="editLoading"/>

设置位置
<my-confirm-button @click="submit" :validate="editFormvalidate" :placement="'top-start'" :loading="editLoading">
    <template #content>
      <p>确定要更新基本信息吗？</p>
    </template>
    更新基本信息
</my-confirm-button>
-->
<template>
  <span style="display: inline-block;">
    <el-dialog
      title="提示"
      :visible.sync="disabledDialog"
      append-to-body
      width="500px"
      top="25vh"
    >
      <slot name="content">
        <h1 class="deleteText">
          <i class="orange el-icon-question"></i
          >{{ type === "delete" ? "确定要删除该条信息吗？" : confirmText }}
        </h1>
      </slot>

      <span slot="footer">
        <el-button type="primary" icon="el-icon-check" @click="sure(false)"
          >确定</el-button
        >
        <el-button icon="el-icon-close" @click="disabledDialog = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <el-link
      v-if="!btnType"
      type="danger"
      :icon="icon"
      :loading="loading"
      :disabled="disabled"
      @click="valid"
      >{{ buttonText }}</el-link
    >
    <el-button
      v-else
      :icon="icon"
      :type="buttonType"
      :loading="loading"
      :disabled="disabled"
      _button
      @click="valid"
    >
      <slot
        ><el-link type="danger">{{ buttonText }}</el-link>
      </slot>
    </el-button>
  </span>
</template>

<script>
export default {
  name: "MyConfirmButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "确定提交吗？",
    },
    content: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: "top-end",
    },
    btnType: {
      type: Boolean,
      default: false, // 默认 el-link
    },
    type: {
      type: String,
      default: "danger", // 提交 primary 删除 delete danger
    },
    buttonType: {
      type: String,
      default: "primary", // 按钮样式 没有按钮text 提交 primary 删除 delete danger
    },
    icon: {
      type: String,
      default: "",
    },
    validate: {
      type: Function,
      default: null,
    },
    validateData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      visible: false,
      disabledDialog: false,
      // buttonType: this.type === 'delete' ? 'danger' : (this.type === 'submit' ? 'primary' : this.type),
      buttonText:
        this.type === "delete"
          ? "删除"
          : this.type === "submit"
          ? "提交"
          : "重置密码",
      style: "",
    };
  },
  computed: {},
  watch: {},
  mounted: function() {},
  methods: {
    hide() {
      if (this.validate) {
        this.disabledDialog = true;
      }
    },
    //
    valid() {
      //
      if (this.validate) {
        const isValid = this.validate(this.validateData);
        console.log("isvalid", isValid, this.validateData);
        if (isValid) {
          this.disabledDialog = true;
        }
      } else {
        this.disabledDialog = true;
      }
    },
    sure() {
      this.disabledDialog = false;
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
>>> .el-dialog__footer {
  border-top: 1px solid #e6e6e6;
}
.deleteText {
  font-size: 16px;
  font-weight: normal;
}
.orange {
  color: #fe9400;
  font-size: 18px;
  margin-right: 5px;
}
.el-button--primary .el-link.el-link--danger {
  color: #fff;  
}
</style>
